import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/joy/Box";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../../../store/auth";
import mapboxgl from "mapbox-gl";
import { Sheet, Typography } from "@mui/joy";
import "mapbox-gl/dist/mapbox-gl.css";
import { getChatMapResponse } from "../../../../store/entities/chat";

const styles: any = {
  londonCycle: "mapbox://styles/mapbox/light-v9",
  outdoor: "mapbox://styles/mapbox/light-v9",
  dark: "mapbox://styles/mapbox/dark-v9",
  basic: "mapbox://styles/mapbox/basic-v9",
  light: "mapbox://styles/mapbox/outdoors-v10",
};

mapboxgl.accessToken =
  "pk.eyJ1Ijoia29rZXBhbGxpIiwiYSI6ImNscjIydWhjbzBkYW8ya284c2xia2FnMGEifQ.qjESTzuiZitHgmTk2jtAPw";

const Tooltip = ({ feature }: any) => {
  if (!feature) return null;

  const renderTooltipContent = () => (
    <>
      {Object.entries(JSON.parse(feature.properties.description)).map(
        ([key, value]: any, index: number) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Typography level="body-sm">{key}</Typography>
            <Typography level="body-sm" fontWeight="bold">
              {value}
            </Typography>
          </Box>
        )
      )}
    </>
  );
  return (
    <Sheet
      style={{
        position: "absolute",
        left: feature.x,
        top: feature.y,
        backgroundColor: "white",
        padding: "5px",
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
      }}
    >
      {renderTooltipContent()}
    </Sheet>
  );
};

const colorCodes: any = {
  light: {
    bubbleColor: "#f4beb5",
    nameColor: "#000",
  },
  dark: {
    bubbleColor: "red",
    nameColor: "#fff",
  },
};

const MapComponentView: React.FC<any> = () => {
  const [hoveredFeature, setHoveredFeature] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //  const themeMode = useSelector(getThemeMode);
  const themeMode = useSelector(getThemeMode);

  const mapRef = useRef<mapboxgl.Map>();
  const mapContainerRef = useRef(null);

  const chatMapResponse = useSelector(getChatMapResponse) || [];

  useEffect(() => {
    if(isLoading) return;
    setIsLoading(true);
    if (mapContainerRef.current) {
      //&& !mapRef.current
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current, // container ID
        style: styles[themeMode], // style URL
        center: [55.199603, 25.061008], // starting position [lng, lat]
        zoom: 10.079935, // starting zoom
      });

      mapRef.current?.on("error", (error) => {
        console.error("Mapbox error:", error);
      });

      // mapRef.current?.on('load', () => {
      //   console.log('inside 2')
      //   if (mapRef.current && mapRef.current.getSource("markers")) {
      //     mapRef.current.removeLayer("text-labels");
      //     mapRef.current.removeLayer("markers");
      //     mapRef.current.removeSource("markers");
      //   }
      // });
    }

    // setMapState(map);
    setIsLoading(false);
    return () => {
      if (mapRef.current) {
        //     // Remove event listeners
        mapRef.current.off("mouseenter", "markers", handleMouseEnter);
        mapRef.current.off("mouseleave", "markers", handleMouseLeave);
        // mapRef.current.remove(); // Cleanup map instance on component unmount
      }
    };
  }, [themeMode]);

  useEffect(() => {
    if(isLoading) return;
    setIsLoading(true);
    if (mapRef.current) {
      const map = mapRef.current;
      // const oldStyles = map.getStyle();
      // console.log('styles =>', map.getStyle())
      // Clear existing layers and sources
      // Remove existing layers and source if they exist
      if (map.getLayer("text-labels")) {
        map.removeLayer("text-labels");
      }
      if (map.getLayer("markers")) {
        map.removeLayer("markers");
      }
      if (map.getSource("markers")) {
        map.removeSource("markers");
      }
      //  map.setStyle({...oldStyles, sprite: styles[themeMode]});
      map.isStyleLoaded()
        ? displayMarkers(map)
        : map.on("load", () => displayMarkers(map));

      // map.setStyle(styles[themeMode]);
    }
    setIsLoading(false);
    return () => {
      // Cleanup event listeners and remove map instance
      if (mapRef.current) {
        mapRef.current.off("mouseenter", "markers", handleMouseEnter);
        mapRef.current.off("mouseleave", "markers", handleMouseLeave);
        // mapRef.current.remove();
      }
    };
  }, [chatMapResponse, themeMode]);

  const displayMarkers = (map: any) => {
    if (map && map.getSource("markers")) {
      map.removeLayer("text-labels");
      map.removeLayer("markers");
      map.removeSource("markers");
    }
    if (chatMapResponse.length > 0) {
      // map.on("load", () => {
      // Add new source with updated data
      map.addSource("markers", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: chatMapResponse,
        },
      });

      // Add new layer with updated source
      map.addLayer({
        id: "markers",
        type: "circle",
        source: "markers",
        paint: {
          "circle-radius": ["get", "radius"],
          "circle-color": ["get", "color"],
        },
      });

      map.addLayer({
        id: "text-labels",
        type: "symbol",
        source: "markers",
        layout: {
          "text-field": ["get", "label"],
          "text-size": 12,
          "text-anchor": "center",
          "text-offset": [0, -0.5], // Adjust to position text above, below, or right on the circle
        },
        paint: {
          "text-color": colorCodes[themeMode]?.nameColor, // Set text color
        },
      });

      // Event listeners for showing tooltip
      map.on("mouseenter", "markers", handleMouseEnter);

      map.on("mouseleave", "markers", handleMouseLeave);
      // })
    }
  };

  const handleMouseEnter = (e: any) => {
    const map = mapRef.current;
    if (map) {
      map.getCanvas().style.cursor = "pointer";

      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setHoveredFeature({
        properties: { description },
        x: e.point.x,
        y: e.point.y,
      });
    }
  };

  const handleMouseLeave = () => {
    const map = mapRef.current;
    if (map) {
      map.getCanvas().style.cursor = "";
      setHoveredFeature(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: 10,
        overflow: "hidden",
        flex: 1,
        maxWidth: { xs: "100%", md: "50dvw" },
        position: "relative",
      }}
    >
      <div
        ref={mapContainerRef}
        id="map"
        style={{ width: "100%", height: "100%" }}
      />
      <Tooltip feature={hoveredFeature} />
    </Box>
  );
};

export default MapComponentView;
