import React from "react";
import SidePaneView from "./side-pane-view";

interface iSidePaneParams {
  isDrawerOpen: boolean;
  toggleDrawer(): void;
}

const SidePane: React.FC<iSidePaneParams> = ({
  isDrawerOpen,
  toggleDrawer,
}) => {
  return (
    <SidePaneView
      isSidePaneOpen={isDrawerOpen}
      toggleSidePane={toggleDrawer}
    />
  );
};

export default SidePane;
