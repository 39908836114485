import React from "react";
import DrawerComponentView from "./drawer-component-view";
import useDrawerController from "./drawer-controller";

interface iDrawerComponentProps {
  isDrawerOpen: boolean;
  toggleDrawer(): void;
  handleToLogoutUser(): void;
}

const DrawerComponent: React.FC<iDrawerComponentProps> = ({
  isDrawerOpen = false,
  toggleDrawer,
  handleToLogoutUser,
}) => {
  const {
    userDetails,
    newThreadLoadingStatus,
    selectedLanguage,
    handleOnchangeLanguage,
    handleAddingNewThread,
  } = useDrawerController();
  return (
    <DrawerComponentView
      userDetails={userDetails}
      isDrawerOpen={isDrawerOpen}
      selectedLanguage={selectedLanguage}
      newThreadLoadingStatus={newThreadLoadingStatus}
      handleOnchangeLanguage={handleOnchangeLanguage}
      handleAddingNewThread={handleAddingNewThread}
      toggleDrawer={toggleDrawer}
      handleToLogoutUser={handleToLogoutUser}
    />
  );
};

export default DrawerComponent;
