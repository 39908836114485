import React from "react";
import SideBarComponentView from "./side-bar-component-view";
import useSidebarController from "./side-bar-controller";

const SideBarComponent: React.FC<{}> = () => {
  const {
    allThreads,
    activeThread,
    selectedThreadEditText,
    selectedEditThreadId,
    newThreadLoadingStatus,
    heightOFAComponent,
    handleToSelectEditThreadTitleId,
    handleThreadTitleEditMethod,
    handleOnThreadsClick,
    handleOnDeleteThread,
    handleOnClickSubmitEditedTitle,
    handleOnCancelEditing,
  } = useSidebarController();
  return (
    <SideBarComponentView
      allThreads={allThreads}
      activeThread={activeThread}
      heightOFAComponent={heightOFAComponent}
      selectedThreadEditText={selectedThreadEditText}
      selectedEditThreadId={selectedEditThreadId}
      newThreadLoadingStatus={newThreadLoadingStatus}
      handleOnThreadsClick={handleOnThreadsClick}
      handleOnDeleteThread={handleOnDeleteThread}
      handleToSelectEditThreadTitleId={handleToSelectEditThreadTitleId}
      handleThreadTitleEditMethod={handleThreadTitleEditMethod}
      handleOnCancelEditing={handleOnCancelEditing}
      handleOnClickSubmitEditedTitle={handleOnClickSubmitEditedTitle}
    />
  );
};

export default SideBarComponent;
