import React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { Info, Logout } from "@mui/icons-material";
import { Box, Button } from "@mui/joy";

const supportEmail = "anil@dxbgpt.ae";

interface iRestrictedUserComponentViewParams {
  onRefresh(): void;
  onLogout(): void;
  restrictedUserData: any;
}

const RestrictedUserComponentView: React.FC<
  iRestrictedUserComponentViewParams
> = ({ restrictedUserData, onRefresh = () => {}, onLogout = () => {} }) => {

  return (
    <Modal
      open={restrictedUserData && !restrictedUserData?.is_active}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 0,
        zIndex: 9999
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 300,
          borderRadius: 10,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
          maxWidth: "20%",
          border: 0
        }}
      >
        <Info sx={{ rotate: "180deg" }} />
        <Typography
          component="h2"
          id="close-modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
        >
          Access Denied
        </Typography>
        <Typography component="body">
          Your account with email id <b>{restrictedUserData ? restrictedUserData.email : "ERROR"}</b> has not been approved yet. 
          Please contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button color="primary" variant="plain" onClick={onRefresh}>
            refresh
          </Button>
          <Button
            startDecorator={<Logout />}
            sx={{ borderRadius: 20 }}
            onClick={onLogout}
          >
            Logout
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default RestrictedUserComponentView;
