import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import * as actions from "./actions/api";
import { storeUserPreferencesData, getUserPreferencesStorageData } from "../services/localstorage-service";

const initUserState = () => {
  let user: any = {
    email: null,
    userName: null,
    profilePic: null,
    uid: null,

    refreshToken: null,
    accessToken: null,
  };

  if (
    typeof window !== "undefined" &&
    localStorage &&
    localStorage.getItem("@user")
  )
    user = JSON.parse(localStorage.getItem("@user") as any);

  return user;
};

const initialState = {
  data: {},
  language: getUserPreferencesStorageData("language"),
  isLoggedIn: null,
  restrictedData: null,
  themeMode: getUserPreferencesStorageData("theme")
} as any;

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateLoggedInStatus: (auth, { payload }) => {
      auth.isLoggedIn = payload;
    },
    updateUserDetailAndToken: (auth, { payload }) => {
      auth.data = { ...payload };
      auth.isLoggedIn = true;
      // localStorage.setItem("@user", JSON.stringify(auth.data));
    },
    updateAuthToken: (auth, { payload }) => {
      // localStorage.removeItem("@user");
      auth.data = { ...auth.data, accessToken: payload };
      // localStorage.setItem("@user", JSON.stringify(auth.data));
    },
    logoutUser: (auth) => {
      auth.data = {
        email: null,
        userName: null,
        profilePic: null,
        uid: null,

        refreshToken: null,
        accessToken: null,
      };
      localStorage.removeItem("@user");
      auth.isLoggedIn = false;
    },
    updateLanguage: (auth, { payload }) => {
      auth.language = payload;
    },
    restrictedApiStart: () => {},
    restrictedApiResponse: (auth, { payload }) => {
      auth.restrictedData = payload;
    },
    restrictedApiFailed: () => {},
    updateThemeMode: (auth, {payload}) => {
      auth.themeMode = payload;
    }
  },
});

export const {
  updateLoggedInStatus,
  updateUserDetailAndToken,
  updateAuthToken,
  logoutUser,
  updateLanguage,
  restrictedApiStart,
  restrictedApiResponse,
  restrictedApiFailed,
  updateThemeMode
} = slice.actions;

export default slice.reducer;

export const getLoggedInStatus = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth.isLoggedIn
);

export const getSelectedLanguage = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth?.language || "en"
);

export const getRestrictedUserDetail = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth?.restrictedData || null,
)

export const updateUserLoggedInStatus =
  (cond: boolean) => (dispatch: Dispatch) => {
    return dispatch({ type: updateLoggedInStatus.type, payload: cond });
  };

export const updateWebsiteThemeMode = (value: string) => (dispatch: Dispatch) => {
  return dispatch({type: updateThemeMode.type, payload: value});
}

export const updateUserLogginDetails = (data: any) => (dispatch: Dispatch) => {
  return dispatch({ type: updateUserDetailAndToken.type, payload: data });
};

export const usedToLogoutUser = () => (dispatch: Dispatch) => {
  return dispatch({ type: logoutUser.type });
};

export const getUserData = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth?.data || {}
);

export const updateSelectedLanguage =
  (lang: string) => (dispatch: Dispatch) => {
    storeUserPreferencesData("language", lang) // use to update language in localstorage
    return dispatch({ type: updateLanguage.type, payload: lang });
  };

export const callToGetRestrictedUserApi = () => (dispatch: Dispatch) => {
  return dispatch(
    actions.apiCallBegin({
      url: "/user/",
      onStart: restrictedApiStart.type,
      onSuccess: restrictedApiResponse.type,
      onError: restrictedApiFailed.type,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
      auth: true,
    })
  );
};

export const getThemeMode = createSelector(
  (state: any) => state.auth,
  (auth: any) => auth?.themeMode || ''
)
