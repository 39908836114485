import React, { Suspense, useContext, useEffect } from "react";
import Box from "@mui/joy/Box";

import MapComponent from "./components/map-component";
import DrawerComponent from "./components/drawer-component";
import { DrawerContext } from "../../components/context/drawer-context";
import SidePane from "./components/side-pane";
import { Sheet, Stack } from "@mui/joy";
import ChipsComponent from "./components/chips-component";
import RestrictedUserComponent from "./components/restricted-user-component";

const ChatComponent = React.lazy(() => import("./components/chat-component"));

const DashboardView: React.FC<{}> = () => {
  const { isWebDrawerOpen, toggleWebDrawer, isMapVisible } = useContext(DrawerContext) as any;

  return (
    <>
      <Sheet
        component="main"
        variant="soft"
        color="neutral"
        sx={{
          // height: "100%", // 55px is the height of the NavBar
          display: "flex",
          flex: 1,
          // minHeight: { xs: "100dvh", md: "92dvh" },
          height: { xs: "91dvh", md: "92dvh" },
          gap: {xs: isMapVisible? 1 : 0, md: 1},
          flexDirection: { xs: "column-reverse", md: "row" },
          // gridTemplateColumns: { xs: "auto", md: "40% 60%" },
          // gridTemplateRows: {xs: "auto", md: "auto 1fr auto"},
        }}
        style={{padding: 8}}
      >
        <SidePane isDrawerOpen={isWebDrawerOpen} toggleDrawer={toggleWebDrawer} />
        
        <Suspense fallback={<></>}>
          <ChatComponent />
        </Suspense>

        {isMapVisible && <MapComponent />}
        {/* <Box sx={{display: {xs: 'block', md: 'none'}, mb: 1}}>
          <ChipsComponent />
        </Box> */}
        {/* <DrawerComponent
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
        /> */}
        <RestrictedUserComponent />
      </Sheet>
    </>
  );
};

export default DashboardView;
