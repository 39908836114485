import httpServices from "../../services/http-services";
import { CACHING_TIME, HttpMethods } from "../constants/http-constants";
import * as actions from "../actions/api";
import { getLocalStorageService } from "../../services/localstorage-service";
import { getCSRFToken } from "../../services/csrf-service";
import { auth as authUser } from "../../firebase";

// for handling caching , last called time is pushed and compared with current time
const apiLastCalledTimeMap = new Map<
  string,
  { url: string; method: string; lastCalled: number }
>();
/**
 * it will check the time difference between last time when api called and the present time
 * @param key to get the last called time from apiLastCalledTimeMap Map
 * @param timeOut caching time out
 * @returns boolean
 */
const isAPICached = (key: string, timeOut: CACHING_TIME) => {
  let previousTime = apiLastCalledTimeMap.get(key)?.lastCalled;
  //if timer is greater than zero and current time minus previous time is less than  given timeOut
  if (
    previousTime &&
    timeOut > CACHING_TIME.INVALIDATE &&
    new Date().getMinutes() - previousTime < timeOut
  )
    return true;
  return false;
};

/**
 *
 * @param url api url
 * @param method api http call method - get, post , put , delete etc.
 * @param data data passed to the api
 * @param auth is api require authentication or not - true or false condition
 * @returns
 */
const createRequest = async (
  url: string,
  method: httpMethodsType = HttpMethods.GET,
  data?: any,
  auth?: boolean,
  header: any | null = {},
  getState?: any
) => {
  const user = authUser?.currentUser as any;
  // let headers: any =  {};
  // const accessToken = getState()?.auth?.data?.accessToken;
  let request: any = {
    baseURL: "/api",
    withCredentials: true,
    method,
    url,
  };

  request.data = data;
  if (header) {
    request.headers = {...header};
  }

  if (auth) {
    const accessToken = await user.getIdToken();
    request.headers["Authorization"] = "Bearer: " + accessToken;

    // return temp;
  }

  if (method !== HttpMethods.GET && auth) {
    // headers.set("X-CSRFToken", getCSRFToken());
    request.headers["X-CSRFToken"] = getCSRFToken();
    // headers.set("Content-Type", "application/json");
  }

  return request;
};

const api =
  ({ dispatch, getState }: { dispatch: AppDispatch; getState: RootState }) =>
  (next: any) =>
  (action: AnyAction) => {
    // calling action type is apiCallBegan then it will call next middlware
    if (action.type !== actions.apiCallBegin.type) return next(action);

    try {
      const {
        url,
        method,
        data,
        onStart,
        onSuccess,
        onError,
        headers,
        auth,
        reducerData,
        cacheValidationDuration,
        onSuccessCallback,
      } = action.payload;

      if (isAPICached(url + method, cacheValidationDuration)) {
        return;
      }

      // call the reducer of onstart type
      if (onStart)
        dispatch({ type: onStart, payload: { reducerData: reducerData } });

      next(action);

      createRequest(url, method?.toLowerCase(), data, headers, auth, getState)
        .then((requestObject) => {
          return httpServices.request(requestObject);
        })
        .then((response) => {
          dispatch(actions.apiCallSuccess(response.data));
          apiLastCalledTimeMap.set(url + method, {
            url: url,
            method: method,
            lastCalled: new Date().getTime(),
          });
          // if onSuccess action call along with the reducer it will dispatch the response on onSuccess type action

          if (onSuccess && reducerData) {
            dispatch({
              type: onSuccess,
              payload: { data: response?.data, reducerData: reducerData },
            });
          } else {
            if (onSuccess) {
              dispatch({
                type: onSuccess,
                payload: response?.data,
              });
            }
          }

          if (onSuccessCallback) {
            onSuccessCallback({
              type: response.data,
              reducerData: reducerData,
            });
          }
        })
        .catch((error: any) => {
          const errorResponse = error?.response?.data
            ? { ...error?.response?.data, status: error?.response?.status }
            : { message: error?.message, status: 400 };

          dispatch(actions.apiCallFailed(errorResponse || "network error"));

          if (onError)
            dispatch({
              type: onError,
              payload: { data: errorResponse, reducerData: reducerData },
            });
        });
    } catch (error) {
      console.log("error =>", error);
    }
  };

export default api;
