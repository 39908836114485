import { useDispatch, useSelector } from "react-redux";
import {
  callToDeleteThread,
  callToEditThread,
  callToGetAllMessages,
  getActiveThreadChat,
  getAllThreads,
  getNewThreadLoadingStatus,
  getWidgetConfigs,
} from "../../store/entities/chat";
import { useCallback, useState } from "react";

const useSidebarController = () => {
  const [selectedThreadEditText, setSelectedThreadEditText] = useState<any>({
    title: null,
    threadId: null,
  });
  const [selectedEditThreadId, setSelectedEditThreadId] = useState<any>(null);

  const threads = useSelector(getAllThreads);
  const activeThread = useSelector(getActiveThreadChat)?.activeThread?.id_slug;
  const newThreadLoadingStatus = useSelector(getNewThreadLoadingStatus);
  const widgetConfigsData = useSelector(getWidgetConfigs);

  const dispatch = useDispatch<Dispatch>();

  const handleOnThreadsClick = useCallback((threadId: number) => {
    dispatch(callToGetAllMessages(threadId));
  }, []);

  const handleOnDeleteThread = (e: any, threadId: number) => {
    e?.stopPropagation();
    setTimeout(() => {
      dispatch(callToDeleteThread(threadId));
    }, 500);
  };

  const handleThreadTitleEditMethod = (event: any) => {
    if (event) {
      const { name, value } = event.target;
      setSelectedThreadEditText((prev: any) => ({
        ...prev,
        title: value,
      }));
    }
  };

  const handleToSelectEditThreadTitleId = (
    e: any,
    threadId: number,
    title: string
  ) => {
    e?.stopPropagation();
    setSelectedEditThreadId(threadId);
    setSelectedThreadEditText({
      title,
      threadId,
    });
  };

  const handleOnClickSubmitEditedTitle = (e: any, threadSlugId: string) => {
    e?.stopPropagation();
    if (selectedThreadEditText?.threadId) {
      const data = {
        title: selectedThreadEditText.title,
        id_slug: threadSlugId,
      };

      dispatch(callToEditThread(selectedThreadEditText.threadId, data));
      setSelectedThreadEditText({
        title: null,
        threadId: null,
      });
    }
  };

  const handleOnCancelEditing = (e: any) => {
    e?.stopPropagation();
    setSelectedThreadEditText({
      title: null,
      threadId: null,
    });
  };

  const heightOFAComponent =
    Object.entries(widgetConfigsData)?.length > 0 ? "50%" : "100%";
  const allThreads = [...threads];

  return {
    allThreads,
    activeThread,
    selectedThreadEditText,
    selectedEditThreadId,
    newThreadLoadingStatus,
    heightOFAComponent,
    handleToSelectEditThreadTitleId,
    handleOnThreadsClick,
    handleOnDeleteThread,
    handleThreadTitleEditMethod,
    handleOnCancelEditing,
    handleOnClickSubmitEditedTitle,
  };
};

export default useSidebarController;
