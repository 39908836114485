import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";
import apiMiddleware from "./middlewares/api-middleware";

//configure the reducer store
export const store: Dispatch = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend([apiMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;