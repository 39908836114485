import React from "react";
import RestrictedUserComponentView from "./restricted-user-component-view";
import useRestrictedUserComponentController from "./restricted-user-component-controller";


const RestrictedUserComponent: React.FC<{}> = () => {
  const { handleOnRefresh, handleOnLogout, restrictedUserData } =
    useRestrictedUserComponentController();

  return (
    <RestrictedUserComponentView
      onRefresh={handleOnRefresh}
      onLogout={handleOnLogout}
      restrictedUserData={restrictedUserData}
    />
  );
};

export default RestrictedUserComponent;
