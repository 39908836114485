import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/joy";
import { Done } from "@mui/icons-material";
import OneLineTextEllipseWrapper from "../common/one-line-test-ellipse-wrapper";

const DropdownComponent: React.FC<any> = (props) => {
  return (
    <Accordion sx={{ ph: 1, pt: 0.5, pb: 0.5 }}>
      <AccordionSummary
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 0.7,
        }}
      >
        <Typography level="title-sm">{props?.label_name}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <OneLineTextEllipseWrapper>
            {/* {props?.dropdownOptionsData?.newOptions
              ? props?.options[props?.dropdownOptionsData?.newOptions]
              : props?.options[props?.default_val]} */}
              <Typography level="body-sm">{props?.options[props?.default_val]}</Typography>
          </OneLineTextEllipseWrapper>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography level="body-xs" sx={{ mb: 0.5 }}>
          {props?.help_text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {Object.entries(props?.options)?.map(
            ([key, value]: any, index: number) => {
              return (
                <IconButton
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => props.handleOnSelectDropdownOption(key)}
                >
                  <Typography level="body-sm">{value}</Typography>
                  {key ===
                    (props?.currentData
                      ? props?.currentData
                      : props?.default_val) && (
                    <Done sx={{ width: 17 }} color="warning" />
                  )}
                </IconButton>
              );
            }
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DropdownComponent;
