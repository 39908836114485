import React from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/joy";
import SideBarThreadComponent from "./component/side-bar-thread";

interface iDrawerComponentViewProps {
  selectedThreadEditText: any;
  selectedEditThreadId: number;
  heightOFAComponent: any;
  allThreads: any;
  activeThread: number;
  newThreadLoadingStatus: boolean;
  handleOnThreadsClick(_: number): void;
  handleOnDeleteThread(e: any, _: number): void;
  handleToSelectEditThreadTitleId(e: any, _: number, t: string): void;
  handleThreadTitleEditMethod(_: any): void;
  handleOnClickSubmitEditedTitle(_: any, d: any): void;
  handleOnCancelEditing(_: any): void;
}

const SkeletonComponent = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Stack
        sx={{
          variant: "soft",
          maxWidth: "100%",
          width: "100%",
          minWidth: "auto",
          display: { xs: "flex", lg: "flex" },
          borderTop: 0,
          borderRadius: "lg",
          p: 1,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Typography
            sx={{ position: "relative", overflow: "hidden" }}
            level="body-xs"
          >
            <Skeleton loading={true}>
              <i>Lorem ipsum</i> is placeholder text commonly used in the
              graphic, print, and publishing industries for previewing,
              publishing industries for previewing
            </Skeleton>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const SideBarComponentView: React.FC<iDrawerComponentViewProps> = ({
  allThreads = [],
  activeThread = 0,
  selectedThreadEditText,
  selectedEditThreadId,
  newThreadLoadingStatus = false,
  heightOFAComponent,
  handleOnThreadsClick = (_: number) => {},
  handleOnDeleteThread = (e: any, _: number) => {},
  handleToSelectEditThreadTitleId = (e: any, _: number) => {},
  handleThreadTitleEditMethod = (_: any) => {},
  handleOnCancelEditing,
  handleOnClickSubmitEditedTitle,
}) => {
  return (
    <Box
      sx={{
        mt: 1,
        overflowY: "auto",
        height: { xs: "100%", md: heightOFAComponent },
        maxHeight: "100%",
        pb: 5,
        "&::-webkit-scrollbar": {
          width: "3px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      {/* ---- starred list ---- */}
      <Typography color="danger" level="body-sm" sx={{ mb: 1 }}>
        Starred
      </Typography>
      {allThreads?.length !== 0 &&
        allThreads?.map((thread: any) => {
          return (
            thread?.is_starred && (
              <SideBarThreadComponent
                key={thread.id}
                allThreads={allThreads}
                thread={thread}
                activeThread={activeThread}
                selectedThreadEditText={selectedThreadEditText}
                heightOFAComponent={heightOFAComponent}
                newThreadLoadingStatus={newThreadLoadingStatus}
                selectedEditThreadId={selectedEditThreadId}
                handleOnThreadsClick={handleOnThreadsClick}
                handleOnDeleteThread={handleOnDeleteThread}
                handleToSelectEditThreadTitleId={
                  handleToSelectEditThreadTitleId
                }
                handleThreadTitleEditMethod={handleThreadTitleEditMethod}
                handleOnClickSubmitEditedTitle={handleOnClickSubmitEditedTitle}
                handleOnCancelEditing={handleOnCancelEditing}
              />
            )
          );
        })}

      {/* ---- history ---- */}
      <Typography color="danger" level="body-sm" sx={{ mb: 1, mt: 2 }}>
        History
      </Typography>
      {newThreadLoadingStatus && <SkeletonComponent />}
      {allThreads?.length !== 0 &&
        allThreads?.map((thread: any) => {
          return (
            !thread?.is_starred && (
              <SideBarThreadComponent
                key={thread.id}
                allThreads={allThreads}
                thread={thread}
                activeThread={activeThread}
                selectedThreadEditText={selectedThreadEditText}
                heightOFAComponent={heightOFAComponent}
                newThreadLoadingStatus={newThreadLoadingStatus}
                selectedEditThreadId={selectedEditThreadId}
                handleOnThreadsClick={handleOnThreadsClick}
                handleOnDeleteThread={handleOnDeleteThread}
                handleToSelectEditThreadTitleId={
                  handleToSelectEditThreadTitleId
                }
                handleThreadTitleEditMethod={handleThreadTitleEditMethod}
                handleOnClickSubmitEditedTitle={handleOnClickSubmitEditedTitle}
                handleOnCancelEditing={handleOnCancelEditing}
              />
            )
          );
        })}
    </Box>
  );
};

export default SideBarComponentView;
