import React from "react";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import SideBarComponent from "../../../../components/side-bar-component";
import {
  Add,
  KeyboardArrowDown,
  Language,
  Logout,
  Podcasts,
  TrendingUp,
} from "@mui/icons-material";
import ColorSchemeToggle from "../../../../components/color-scheme-toggle";
import { LANGUAGES_CONSTANT } from "../../../../resources/constant";
import WidgetComponent from "../../../../components/widget-component";

interface iDrawerComponentViewProps {
  isDrawerOpen: boolean;
  userDetails: any;
  newThreadLoadingStatus: boolean;
  selectedLanguage: string;
  handleOnchangeLanguage: any;
  handleAddingNewThread(): void;
  toggleDrawer(): void;
  handleToLogoutUser(): void;
}

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

const avatarPlaceholder =
  "https://camo.githubusercontent.com/c6fe2c13c27fe87ac6581b9fe289d2f071bd1b4ef6f3e3c5fc2aba0bbc23fd88/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f372f37632f50726f66696c655f6176617461725f706c616365686f6c6465725f6c617267652e706e67";

const DrawerComponentView: React.FC<iDrawerComponentViewProps> = ({
  isDrawerOpen = false,
  userDetails,
  newThreadLoadingStatus = false,
  selectedLanguage,
  toggleDrawer = () => {},
  handleToLogoutUser = () => {},
  handleAddingNewThread = () => {},
  handleOnchangeLanguage = () => {},
}) => {
  return (
    <Drawer
      open={isDrawerOpen}
      color="neutral"
      variant="soft"
      onClose={toggleDrawer}
      sx={{ display: { xs: "block", md: "none" }, p: 2 }}
    >
      <Stack sx={{ p: 1, height: "100%", overflowY: 'auto'  }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle level="title-lg" sx={{ textAlign: "center", }}>
            Threads
          </DialogTitle>
          <Button
            variant="soft"
            color="primary"
            loading={newThreadLoadingStatus}
            onClick={handleAddingNewThread}
            sx={{ p: 1, borderRadius: 100 }}
          >
            <Add />
          </Button>
        </Box>

        <Box sx={{ p: 1.5}}>
          <SideBarComponent />
        </Box>
      </Stack>
      <Stack sx={{ p: 2 }}>
        <Sheet
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 1,
            borderRadius: 10, justifyContent: 'flex-end'
          }}
        >
          {/* <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            gap={1}
            onClick={() => {}}
          >
            <Podcasts sx={{ width: 17 }} />
            <Typography>News</Typography>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            gap={1}
            onClick={() => {}}
          >
            <TrendingUp sx={{ width: 17 }} />
            <Typography>Top Areas</Typography>
          </Stack> */}
          {/* <Box sx={{height: "50%", overflowY: 'auto'}}> */}
          <WidgetComponent />
          {/* </Box> */}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)} color="neutral">
                  <Language />
                  <ListItemContent sx={{ ml: 1 }}>
                    <Typography level="title-sm">{selectedLanguage}</Typography>
                  </ListItemContent>
                  <KeyboardArrowDown
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5, ml: 6 }}>
                {LANGUAGES_CONSTANT?.map((lang: any) => {
                  return (
                    <ListItem
                      key={lang?.id}
                      sx={{ mt: 0.5 }}
                      onClick={() => handleOnchangeLanguage(lang?.id)}
                    >
                      <ListItemButton>{lang?.text || ""}</ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Toggler>
          </ListItem>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              ml: -1,
            }}
            // gap={1}
          >
            <ColorSchemeToggle needToShowThemeText={true} />
          </Stack>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <Box
                  onClick={() => setOpen(!open)}
                  sx={{
                    gap: 1,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {userDetails?.profilePic && (
                    <Avatar
                      variant="outlined"
                      size="sm"
                      src={userDetails?.profilePic || avatarPlaceholder}
                    />
                  )}
                  <Box sx={{ minWidth: 0, flex: 1 }}>
                    {userDetails?.userName && (
                      <Typography level="title-sm">
                        {userDetails?.userName || ""}
                      </Typography>
                    )}
                    {userDetails?.email && (
                      <Typography level="body-xs">
                        {userDetails?.email || ""}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            >
              <List sx={{ gap: 0.5, ml: 6 }}>
                <ListItem onClick={handleToLogoutUser} sx={{ mt: 1 }}>
                  <Logout /> Logout
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
        </Sheet>
      </Stack>
    </Drawer>
  );
};

export default DrawerComponentView;
