import { signInWithPopup, signOut } from "firebase/auth";

import { auth, provider } from "../firebase";
import { useDispatch } from "react-redux";
import { updateUserLogginDetails, usedToLogoutUser } from "../store/auth";

class FirebaseAuthProvider {
  auth: any;
  provider: any;
  dispatch: any = useDispatch<Dispatch>();
  constructor() {
    this.auth = auth;
    this.provider = provider;
  }

  signInWithPopUp = async () => {
    try {
      const response: any = await signInWithPopup(this.auth, this.provider);

      const user = {
        email: response.user?.email || "",
        userName: response.user?.displayName || "",
        profilePic: response.user?.photoURL || "",
        accessToken: response.user?.accessToken,
        refreshToken: response?.user?.refreshToken || null,
        uid: response.user?.uid || null,
      };

      this.dispatch(updateUserLogginDetails(user));
    } catch (error) {
      console.log("sign in error =>>", error);
    }
  };

  signOutTheUser = () => {
    signOut(auth)
      .then(() => {
        this.dispatch(usedToLogoutUser());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default FirebaseAuthProvider;
