import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usedToLogoutUser } from "../../../../store/auth";
import { getRestrictedUserDetail } from "../../../../store/auth";

const useRestrictedUserComponentController = () => {
  const dispatch = useDispatch<Dispatch>();

  const restrictedUserData = useSelector(getRestrictedUserDetail);

  const handleOnRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleOnLogout = useCallback(() => {
    dispatch(usedToLogoutUser());
  }, [dispatch]);

  return { handleOnRefresh, handleOnLogout, restrictedUserData };
};

export default useRestrictedUserComponentController;
