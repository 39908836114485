const setCSRFToken = () => fetch("/ensure-csrf/", { method: "GET" });

const getCookie = (name: any) => {
 let cookieValue = null;
 if (document.cookie && document.cookie !== "") {
     const cookies = document.cookie.split(";");
     for (let i = 0; i < cookies.length; i++) {
         const cookie = cookies[i].trim();
         if (cookie.substring(0, name.length + 1) === name + "=") {
         cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
         break;
         }
 }
 }
 return cookieValue;
}

export const getCSRFToken = () => {
 const csrftoken = getCookie("csrftoken");
 if (csrftoken === null) {
     return setCSRFToken().then(_ => getCookie("csrftoken"));
} else {
 return csrftoken;
 }
}