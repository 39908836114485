import React, { useEffect, useState } from "react";

import { Clear, Done } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  Typography,
} from "@mui/joy";
import OneLineTextEllipseWrapper from "../common/one-line-test-ellipse-wrapper";

const MultiSelectDropdown: React.FC<any> = (props) => {
  const defaultValues = props?.default_val?.map((k: string) => props.options[k]).slice()?.join(', ');
  const defaultText = defaultValues?.toString()?.trim();
  return (
    <Accordion sx={{ ph: 1, pt: 0.5, pb: 0.5 }}>
      <AccordionSummary
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 0.7,
        }}
      >
        <Typography level="title-sm">{props?.label_name}</Typography>
        <OneLineTextEllipseWrapper>
          <Typography level="body-sm">{defaultText}</Typography>
        </OneLineTextEllipseWrapper>
      </AccordionSummary>
      <AccordionDetails>
      <Typography level="body-xs" sx={{mb: 0.5}}>{props?.help_text}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            // width: 400,
            
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              width: "3px",
              height: 2,
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {props?.currentData?.map(
            (item: any, index: number) => {
              return (
                <Chip
                  key={index}
                  variant="outlined"
                  endDecorator={<Clear />}
                  sx={{ px: 2, py: 0.5, mb: 0.5 }}
                  onClick={() => props.handleOnSelectMultiDropDownOptions(item)}
                >
                  {props?.options[item]}
                </Chip>
              );
            }
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {Object.entries(props?.options)?.map(
            ([key, value]: any, index: number) => {
              return (
                <IconButton
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  onClick={() => props.handleOnSelectMultiDropDownOptions(key)}
                >
                  <Typography level="body-sm">{value}</Typography>
                  {
                    props.currentData?.includes(key) && (
                    <Done sx={{ width: 17 }} color="warning" />
                  )}
                </IconButton>
              );
            }
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MultiSelectDropdown;
