import { createSelector, createSlice, current } from "@reduxjs/toolkit";
import * as actions from "../actions/api";

const slice = createSlice({
  name: "chat",
  initialState: {
    data: [],
    threads: [],
    activeThread: null,
    addThreadNewId: null,
    threadChats: [],
    pendingChat: {
      isLoading: false,
      chat: null,
      activeThreadId: null,
    },
    isNewAddThreadLoading: false,
  } as any,
  reducers: {
    userChatApiCallingStart: (chat, { payload }) => {
      const { reducerData = {} } = payload;
      // chat.pendingChat[reducerData.activeThreadId].isLoading = true;
      chat.pendingChat[reducerData.activeThreadId] = {
        chat: reducerData,
        isPending: true,
        isLoading: true,
      };
    },
    userChatApiResponse: (chat, { payload }) => {
      const { data = [], reducerData = {} } = payload;
      console.log("data =>>", data);
      // chat.mapResponse = data[0]?.maps_response || [];

      // chat.widgetConfigs = data[0]?.widgets_config || {};
      chat.threadChats = [...data, ...chat.threadChats];
      chat.pendingChat[reducerData.activeThreadId] = {
        isLoading: false,
        chat: null,
      };
    },
    userChatApiFailed: (chat, { payload }) => {
      console.log("chat api failed =>>", payload);
      const { reducerData = {} } = payload;
      console.log('sfd =>>', current(chat?.pendingChat[reducerData?.activeThreadId]))
      chat.threadChats = [chat?.pendingChat[reducerData?.activeThreadId]?.chat?.data, ...chat.threadChats]
      chat.pendingChat[reducerData.activeThreadId] = {
        chat: null,
        isLoading: false,
      };
    },
    getAllChatApiCallingStart: (chat, { payload }) => {},
    getAllChatApiCallResponse: (chat, { payload }) => {
      const {data, reducerData} = payload;
      chat.threadChats = data;
      // console.log('chats =>>', chats?.slice(-1));
      // chat.widgetConfigs = chats?.[0]?.widgets_config || {};
      // chat.mapResponse = chats?.[0]?.maps_response || [];
      chat.data = data;
      chat.addThreadNewId = null;
      chat.activeThread = reducerData?.activeThreadId || "";
    },
    getAllChatApiCallFailed: (chat, { payload }) => {},
    startThreadsApi: (chat) => {},
    responseThreadsApi: (chat, { payload }) => {
      chat.threads = payload;
      chat.addThreadNewId = payload?.[0]?.id_slug || "";
      chat.activeThread = payload?.[0]?.id_slug || "";
    },
    failedThreadsApi: (chat, { payload }) => {
      console.log("threads failed response =>", payload);
    },
    setActiveThread: (chat, { payload }) => {
      chat.activeThread = payload;
      // localStorage.setItem("@activeThread", payload);
    },
    startCallingToAddNewThread: (chat, { payload }) => {
      chat.isNewAddThreadLoading = true;
    },
    responseOfAddedNewThread: (chat, { payload }) => {
      chat.threads = [payload, ...chat.threads];
      // if(chat.threads.length === 1 ){

      chat.addThreadNewId = payload?.id_slug; // this is added to stop to call the same api two time of getting the messages
      chat.activeThread = payload?.id_slug;
      // }
      chat.isNewAddThreadLoading = false;
    },
    failedResponseOfNewThread: (chat, { payload }) => {
      console.log("failed new response =>>", payload);
      chat.isNewAddThreadLoading = false;
    },
    deleteThreadApiStart: (chat, { payload }) => {},
    deleteThreadApiResponse: (chat, { payload }) => {
      const { reducerData } = payload;
      const deletedThreadIndex = [...chat.threads].findIndex(
        (thread: any) => thread.id === reducerData?.threadId
      );
      const finalThreadsData = [...chat.threads].filter(
        (thread) => thread.id !== reducerData?.threadId
      );

      chat.threadChats = [...chat.threadChats].filter(
        (chat) => chat.thread !== reducerData?.threadId
      );
      if (deletedThreadIndex === chat.threads.length - 1) {
        // console.log('call if block =>>', chat.threads[0]?.id_slug)
        chat.activeThread = chat.threads[0]?.id_slug;
        chat.addThreadNewId = chat.threads[0]?.id_slug;
      } else {
        // console.log('call else block =>>', chat.threads[reducerData?.deletedThreadIndex + 1]?.id_slug)
        chat.activeThread = chat.threads[deletedThreadIndex + 1]?.id_slug;
        chat.addThreadNewId = chat.threads[deletedThreadIndex + 1]?.id_slug;
      }
      chat.threads = finalThreadsData;
      // if()
    },
    deleteThreadApiFailed: (chat, { payload }) => {},
    editThreadTitleApiStart: () => {},
    editThreadTitleApiResponse: (chat, { payload }) => {
      const { data } = payload;
      if (data) {
        const threadIndex = chat.threads.findIndex(
          (thread: any) => thread?.id === data?.id
        );

        chat.threads[threadIndex] = data;
      }
    },
    editThreadTitleApiFailed: () => {},
    threadStarredApiStart: () => {},
    threadStarredApiResponse: (chat, { payload }) => {
      console.log("payload =>>", payload);
      const data = payload?.data;
      const result = chat.threads.filter(
        (thread: any) => thread.id !== data.id
      );

      chat.threads = [...result, data];
    },
    threadStarredApiFailed: () => {},
    updateMapResponse: (chat, { payload }) => {
      chat.mapResponse = payload;
    },
  },
});

export const {
  userChatApiCallingStart,
  userChatApiResponse,
  userChatApiFailed,
  getAllChatApiCallingStart,
  getAllChatApiCallResponse,
  getAllChatApiCallFailed,
  startThreadsApi,
  responseThreadsApi,
  failedThreadsApi,
  setActiveThread,
  startCallingToAddNewThread,
  responseOfAddedNewThread,
  failedResponseOfNewThread,
  deleteThreadApiStart,
  deleteThreadApiResponse,
  deleteThreadApiFailed,
  editThreadTitleApiStart,
  editThreadTitleApiResponse,
  editThreadTitleApiFailed,
  threadStarredApiStart,
  threadStarredApiResponse,
  threadStarredApiFailed,
  updateMapResponse,
} = slice.actions;

export default slice.reducer;

export const callToPostUserChat =
  (data: any, threadId: any) => (dispatch: Dispatch, getState: any) => {
    const selectedLanguage = getState()?.auth?.language || "en";

    return dispatch(
      actions.apiCallBegin({
        url: `/messages/?thread_slug=${threadId}`,
        onStart: userChatApiCallingStart.type,
        onSuccess: userChatApiResponse.type,
        onError: userChatApiFailed.type,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...data,
          language: selectedLanguage,
          role: "user",
        },
        reducerData: { data, activeThreadId: threadId },
        method: "POST",
        auth: true,
      })
    );
  };

export const callToGetAllMessages =
  (threadId: number | null = null) =>
  (dispatch: Dispatch, getState: any) => {
    // const chatData = getState()?.threads?.data || [];
    //   console.log('chat data =>>', chatData);
    return dispatch(
      actions.apiCallBegin({
        url: threadId ? `/messages/?thread_slug=${threadId}` : "/messages/",
        onStart: getAllChatApiCallingStart.type,
        onSuccess: getAllChatApiCallResponse.type,
        onError: getAllChatApiCallFailed.type,
        reducerData: {activeThreadId: threadId},
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
        auth: true,
      })
    );
  };

export const callThreadsApi = () => (dispatch: Dispatch) => {
  console.log('hello ...')
  return dispatch(
    actions.apiCallBegin({
      url: "/thread/",
      onStart: startThreadsApi.type,
      onSuccess: responseThreadsApi.type,
      onError: failedThreadsApi.type,
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
      auth: true,
    })
  );
};

export const updateActiveThread =
  (activeThreadNumber: string) => (dispatch: Dispatch) => {
    return dispatch({
      type: setActiveThread.type,
      payload: activeThreadNumber,
    });
  };

export const callToAddNewThread =
  (options: any = null) =>
  (dispatch: Dispatch) => {
    let data = options
      ? options
      : { title: "New Thread", id_slug: "new-thread-" + Date.now() };

    return dispatch(
      actions.apiCallBegin({
        url: "/thread/",
        onStart: startCallingToAddNewThread.type,
        onSuccess: responseOfAddedNewThread.type,
        onError: failedResponseOfNewThread.type,
        headers: {
          "Content-Type": "application/json",
        },
        data,
        method: "POST",
        auth: true,
      })
    );
  };

export const callToDeleteThread =
  (threadId: number) => (dispatch: Dispatch) => {
    return dispatch(
      actions.apiCallBegin({
        url: `/thread/${threadId}`,
        onStart: deleteThreadApiStart.type,
        onSuccess: deleteThreadApiResponse.type,
        onError: deleteThreadApiFailed.type,
        headers: {
          "Content-Type": "application/json",
        },
        reducerData: { threadId },
        method: "DELETE",
        auth: true,
      })
    );
  };

export const callToEditThread =
  (threadId: number, data: any) => (dispatch: Dispatch) => {
    return dispatch(
      actions.apiCallBegin({
        url: `/thread/${threadId}/`,
        onStart: editThreadTitleApiStart.type,
        onSuccess: editThreadTitleApiResponse.type,
        onError: editThreadTitleApiFailed.type,
        headers: {
          "Content-Type": "application/json",
        },
        reducerData: { threadId },
        data,
        method: "PATCH",
        auth: true,
      })
    );
  };

export const callToStarredThread =
  (threadId: number, starredStatus: boolean) => (dispatch: Dispatch) => {
    return dispatch(
      actions.apiCallBegin({
        url: `/thread/${threadId}/`,
        onStart: threadStarredApiStart.type,
        onSuccess: threadStarredApiResponse.type,
        onError: threadStarredApiFailed.type,
        headers: {
          "Content-Type": "application/json",
        },
        reducerData: { threadId },
        data: { is_starred: starredStatus },
        method: "PATCH",
        auth: true,
      })
    );
  };

export const callToUpdateMapResponse = (data: any) => (dispatch: Dispatch) => {
  return dispatch({ type: updateMapResponse.type, payload: data });
};


//------- selectors ------



export const getChatMapResponse = createSelector(
  (state: any) =>{return ({chat: state.chat, auth: state.auth})},
  (data: any) => {
    const themeMode = data?.auth?.themeMode;
    const mapResponses: any = data?.chat?.threadChats?.[0]?.maps_response || {};
    const mapData: any = [];

    const colorCodes: any = {
      light: {
        bubbleColor: "#f4beb5",
        nameColor: "#000",
      },
      dark: {
        bubbleColor: "red",
        nameColor: "#fff",
      },
    };
    
    mapResponses?.items?.forEach((element: any, index: number) => {
      const mapJSON: any = {};
      mapJSON.type = "Feature";
      mapJSON.geometry = {
        type: "Point",
        coordinates: element?.geo_location,
      };
      mapJSON.properties = {
        radius: element?.normalized_score * 50,
        color:  colorCodes[themeMode]?.bubbleColor,
        description: JSON.stringify(element?.display_data),
        label: element?.name,
      };
      mapData.push(mapJSON);
    });
    return mapData || null;
  }
);

export const getWidgetConfigs = createSelector(
  (state: any) => state.chat,
  (data: any) => {
    return data?.threadChats?.[0]?.widgets_config || {};
  }
);

export const getAllChatData = createSelector(
  (state: any) => state.chat,
  (chat: any) => chat?.data || []
);

export const getActiveThread = createSelector(
  (state: any) => state.chat,
  (chat: any) => chat?.activeThread
);

export const getAllThreads = createSelector(
  (state: any) => state.chat,
  (chat: any) => chat?.threads || []
);

export const getUserPendingChatStateAndData = createSelector(
  (state: any) => state.chat,
  (data: any) =>
    data?.pendingChat?.[data.activeThread] || { isLoading: false, chat: {} }
);

export const getActiveThreadData = createSelector(
  (state: any) => state.chat,
  (chat: any) => {
    const activeThread = chat.threads?.filter(
      (thread: any) => thread.id_slug === chat.activeThread
    )[0];
    return activeThread;
  }
);

export const getActiveThreadChat = createSelector(
  (state: any) => state.chat,
  (chat: any) => {
    const activeThread = chat.threads?.filter(
      (thread: any) => thread.id_slug === chat.activeThread
    )[0];
    return { chats: chat?.threadChats || [], activeThread };
  }
);

export const getNewThreadLoadingStatus = createSelector(
  (state: any) => state.chat,
  (chat: any) => chat?.isNewAddThreadLoading || false
);

export const getAddNewThreadId = createSelector(
  (state: any) => state.chat,
  (chat: any) => chat?.addThreadNewId || null,
)
