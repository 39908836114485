import React from "react";

import LoginView from "./login-view";
import useLoginController from "./login-controller";

const Login: React.FC<{}> = () => {
  const { handleOnLogin } = useLoginController();
  return <LoginView onLogin={handleOnLogin} />;
};

export default Login;
