import React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { Box, IconButton, Sheet, Stack, Typography } from "@mui/joy";
import SideBarComponent from "../../../../components/side-bar-component";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import WidgetComponent from "../../../../components/widget-component";

interface iSidePaneViewParams {
  toggleSidePane(): void;
  isSidePaneOpen: boolean;
}

const SidePaneView: React.FC<iSidePaneViewParams> = ({
  isSidePaneOpen = false,
  toggleSidePane = () => {},
}) => {
  return (
    <>
      {!isSidePaneOpen && (
        <IconButton
          onClick={toggleSidePane}
          sx={{
            position: "absolute",
            top: 25,
            left: 6,
            display: { xs: "none", md: "block" },
          }}
          style={{ zIndex: 9999 }}
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
      )}
      {isSidePaneOpen && (
        <Box sx={{ flex: 0.3,
          maxHeight: "100%",
          overflow: 'hidden',
          display: { xs: "none", md: "block" },
          }}>
          <Stack direction="row" justifyContent="space-between">
              <Typography level="title-lg" fontSize={20}>
                Threads
              </Typography>
              <IconButton onClick={toggleSidePane}>
                <KeyboardDoubleArrowLeft />
              </IconButton>
            </Stack>
        <Box
          sx={{
            "--ListItem-radius": "var(--joy-radius-sm)",
            "--List-gap": "4px",

            position: "relative",
            display: { xs: "none", md: "block" },
            // overflow: "auto",
            p: 1,
            flexGrow: 1,
            height: "100%",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          
              <SideBarComponent />
              <WidgetComponent />
            
        </Box>
        </Box>
      )}
    </>
  );
};

export default SidePaneView;
