import React from "react";
import Box from "@mui/joy/Box";
import ColorSchemeToggle from "../../../../components/color-scheme-toggle";

const LoginRightComponent: React.FC<{}> = () => {
  return (
    <Box
      sx={(theme: any) => ({
        height: "100%",
        position: "fixed",
        p: 2,
        right: 0,
        top: 0,
        bottom: 0,
        left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
        transition:
          "background-image var(--Transition-duration), left var(--Transition-duration) !important",
        transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
        backgroundColor: "background.level1",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + require('../../../../assets/images/singin_image.png') + ")" ,
        [theme.getColorSchemeSelector("dark")]: {
          backgroundImage:
          "url(" + require('../../../../assets/images/singin_image.png') + ")",
        },
      })}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <ColorSchemeToggle backGroundColor="#FFD8E4" />
      </Box>
    </Box>
  );
};

export default LoginRightComponent;
