import { createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/api";

const slice = createSlice({
  name: "threads",
  initialState: {
    data: [],
  } as any,
  reducers: {
    startThreadsApi: (threads) => {},
    responseThreadsApi: (threads, { payload }) => {
      console.log("threads data =>", payload);
      threads.data = payload;
    },
    failedThreadsApi: (threads, { payload }) => {
      console.log("threads failed response =>", payload);
    },
  },
});

export const { startThreadsApi, responseThreadsApi, failedThreadsApi } =
  slice.actions;

export default slice.reducer;

// export const callThreadsApi = () => (dispatch: Dispatch) => {
    
//   return dispatch(
//     actions.apiCallBegin({
//       url: "/thread/",
//       onStart: startThreadsApi.type,
//       onSuccess: responseThreadsApi.type,
//       onError: failedThreadsApi.type,
//       headers: {
//         "Content-Type": "application/json",
//       },
//       method: "GET",
//       auth: true,
//     })
//   );
// };
