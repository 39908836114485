import { Fragment } from "react";

import Navbar from "../components/navbar/navbar";
import Error404 from "../components/error404";


const AppError = () => {
  return (
    <Fragment>
      <Navbar />
      <Error404 />
    </Fragment>
  );
};

export default AppError;