import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth";
import chat from './entities/chat';
import threads from "./entities/threads";

// combine all the reducers
export default combineReducers({
  auth,
  chat,
  threads,
});
