import React from "react";
import SliderComponent from "../accordion-slider-component";
import MultiSelectDropdown from "../accordion-multi-select-dropdown";
import DropdownComponent from "../accordion-dropdown-component";
import AccordionGroup from "@mui/joy/AccordionGroup";
import { Box, Button, Sheet, Typography } from "@mui/joy";
import { Tune } from "@mui/icons-material";
import AccordionSwitchComponent from "../accordion-switch-component";

const WidgetComponent = ({
  widgetData,
  handleWidgetController,
}: any) => {
  const GetWidgetComponent = (type = "", args: any, key: string) => {
    const HASH_MAP = new Map([
      [
        "NumSliderWidget",
        <SliderComponent
          {...args}
          handleSliderValueText={(value: any) =>
            handleWidgetController(key, value)
          }
        />,
      ],
      ["StrSliderWidget", <SliderComponent {...args} />],
      [
        "DropdownMultiSelectWidget",
        <MultiSelectDropdown
          {...args}
          handleOnSelectMultiDropDownOptions={(value: any) =>
            handleWidgetController(key, value)
          }
        />,
      ],
      [
        "DropdownWidget",
        <DropdownComponent
          {...args}
          handleOnSelectDropdownOption={(value: any) =>
            handleWidgetController(key, value)
          }
        />,
      ],
      [
        "ToggleWidget",
        <AccordionSwitchComponent
          {...args}
          // checked={checked}
          handleSwitchCase={(value: any) => handleWidgetController(key, value)}
        />,
      ],
    ]);
    return HASH_MAP.get(type);
  };
  return (
    <>
      <AccordionGroup
        variant="soft"
        color="neutral"
        sx={{
          borderRadius: 10,
          // overflow: "hidden",
          height: "calc( 50dvh - 20dvh )",
          // maxHeight: "calc( 50dvh - 20dvh )", // { xs: "30dvh", md: "37dvh", lg: "37dvh" },
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        {Object.entries(widgetData)?.map(([key, value]: any, index: number) => {
          return (
            <div key={index}>
              {GetWidgetComponent(value?.widget_type, value, key)}
            </div>
          );
        })}
      </AccordionGroup>
    </>
  );
};

const WidgetComponentView = ({
  handleWidgetController,
  isLoading,
  widgetConfigurationData,
  handleOnSubmit,
  handleOnReset,
  isMessagePending = false
}: any) => {
  return (
    <>
      {Object.entries(widgetConfigurationData)?.length > 0 && (
        // <WidgetComponentWrapper>
        // <Sheet sx={{height: '50%'}}>
        <Box sx={{ }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Tune sx={{ width: 20, marginBottom: 1 }} />
            <Typography level="body-sm" sx={{ mb: 1 }}>
              Thread Context
            </Typography>
          </Box>
          <Box sx={{mb: 3,  "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },}}>
            <WidgetComponent
              widgetData={widgetConfigurationData}
              handleOnSubmit={handleOnSubmit}
              isLoading={isLoading}
              handleOnReset={handleOnReset}
              handleWidgetController={handleWidgetController}
            />
           
          
          <Box
              sx={{
                gap: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: 1,
              }}
            >
              <Button variant="outlined" disabled={isMessagePending} onClick={handleOnReset}>
                Reset
              </Button>
              <Button disabled={isLoading || isMessagePending} onClick={handleOnSubmit}>
                Submit
              </Button>
            </Box>
            </Box>
        </Box>
      )}
    </>
  );
};

export default WidgetComponentView;
