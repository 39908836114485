import React from "react";
import { Outlet } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";

import Navbar from "../components/navbar";
import DrawerContextProvider from "../components/context/drawer-context";
import LoadingComponent from "../components/loading-component";

interface iAppLayout {
  isLoggedIn: boolean | null;
}

const AppLayout = ({ isLoggedIn = null }: iAppLayout) => {
  if(isLoggedIn ===null){
    return <LoadingComponent />
  }
  return (
    <DrawerContextProvider>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        {isLoggedIn && <Navbar />}
        <Outlet />
      </CssVarsProvider>
    </DrawerContextProvider>
  );
};

export default AppLayout;
