export const LANGUAGES_CONSTANT = [
  {
    id: 'en',
    text: 'English',
  },
  {
    id: 'ar',
    text: 'عربي', // Arabic
  },
  // {
  //  id: 'fr',
  //   text: 'Français', // French
  // },
  // {
  //   id: 'it',
  //   text: 'Italiano', // Italian
  // },
  {
    id: 'ru',
    text: 'Русский', // Russian
  },
  // {
  //   id: 'uk',
  //   text: 'Українська', // Ukrainian
  // },
  {
    id: 'ml',
    text: 'മലയാളം', // Malayalam
  },
  {
    id: 'hi',
    text: 'हिन्दी', // Hindi
  },
];
