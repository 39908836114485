import FirebaseAuthProvider from "../../../utils/firebase-auth";

const useLoginController = () => {

  const firebase = new FirebaseAuthProvider();

  const handleOnLogin = () => {
    firebase.signInWithPopUp();
  };

  return {
    handleOnLogin,
  };
};

export default useLoginController;
