import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Slider,
  Typography,
} from "@mui/joy";
import OneLineTextEllipseWrapper from "../common/one-line-test-ellipse-wrapper";

function valueText(value: number, optionName: any) {
  return typeof optionName === "string" ? optionName : `${value}`;
}

const SliderComponent: React.FC<any> = (props) => {

  const sliderOptions = typeof props?.default_val === "string" ? {} : {
    getAriaValueText: valueText
  }  

  return (
    <Accordion sx={{ ph: 1, pt: 0.5, pb: 0.5 }}>
      <AccordionSummary
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 0.7,
        }}
      >
        <Typography level="title-sm">{props?.label_name}</Typography>
        <OneLineTextEllipseWrapper>
          <Typography sx={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} level="body-sm">
            {
              (props && props.default_val && Array.isArray(props?.default_val))
              ? props.default_val[0] + "-" + props.default_val[1]
              : props?.default_val
            }
          </Typography>
          </OneLineTextEllipseWrapper>
      </AccordionSummary>
      <AccordionDetails>
      <Typography level="body-xs" sx={{mb: 0.5}}>{props?.help_text}</Typography>
        <Box sx={{ m: 2 }}>
          <Slider
            aria-label="Custom marks"
            value={props?.currentData ? props?.currentData : props?.prevData}
            onChange={(_, value) => props.handleSliderValueText(value)}
            step={props?.step_size || 0}
            valueLabelDisplay="auto"
            min={props?.min_val}
            max={props?.max_val}
            // marks={sliderMarks}
            marks
            {...sliderOptions}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SliderComponent;
