export enum HttpMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export enum CACHING_TIME {
  INVALIDATE = 0,
  SHORT = 1, // for testing purpose otherwise its 5 minutes
  LONG = 10,
}
