import React, { useCallback, useState } from "react";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";

import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { formLabelClasses } from "@mui/joy/FormLabel";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

import { ArrowBack, ArrowForward } from "@mui/icons-material";

import GoogleIcon from "../../../../resources/icons/google-icon";
import branding from "../../../../resources/branding";

interface iLoginLeftComponent {
  onLogin(): void;
}

const AuthLoginComponent = ({ onLogin, handleOnClickSignIn }: any) => {
  return (
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack
        gap={1}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <IconButton onClick={handleOnClickSignIn}>
          <ArrowBack />
        </IconButton>
        <Typography level="h3">Sign in</Typography>
        {/* <Typography level="body-sm">
              New to company?{' '}
              <Link href="#replace-with-a-link" level="title-sm">
                Sign up!
              </Link>
            </Typography> */}
      </Stack>

      <Button
        variant="soft"
        color="neutral"
        fullWidth
        startDecorator={<GoogleIcon />}
        onClick={onLogin}
      >
        Continue with Google
      </Button>
    </Stack>
  );
};

const IntroComponent = ({handleOnClickSignIn}: any) => {
  return (
    <Stack gap={4} sx={{ mb: 2 }}>
      <Stack gap={2}>
        {/*<Typography level="body-lg" 
        // sx={{color: '#6750A4'}}
        >The power to do more</Typography>*/}
        <Typography level="body-lg">
            Still second guessing the Dubai Real Estate Market?
        </Typography>
        <Typography level="body-lg">
            Getting pushed around by agents to low quality investments that pay them high commissions and leave you stuck?
        </Typography>
        <Typography level="body-lg">
            No more...
        </Typography>
        <Typography level="h3" sx={{fontWeight: 500}}>
            Use our AI to analyze past performance to make a data-driven investment decision
        </Typography>
        {/*<Typography level="body-sm">
          future projections using AI coming soon - watch this space for more
        </Typography>*/}
        <Box sx={{ width: 400 }}>
          <Button
            startDecorator={<ArrowForward />}
            sx={{ 
              borderRadius: 50, 
              pt: 2, 
              pb: 2, 
              // backgroundColor: '#6750A4', 
              mt: 1 }}
            size="lg"
            onClick={handleOnClickSignIn}
          >
            Get Started
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

const LoginLeftComponent: React.FC<iLoginLeftComponent> = ({
  onLogin = () => {},
}) => {
  const [isAuthComponent, setIsAuthComponent] = useState<boolean>(false);

  const handleOnClickSignIn = useCallback(() => {
    setIsAuthComponent(!isAuthComponent);
  }, [setIsAuthComponent, isAuthComponent]);

  return (
    <Box
      sx={(theme: any) => ({
        width:
          "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
        transition: "width var(--Transition-duration)",
        transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
        position: "fixed",
        zIndex: 1,
        display: "flex",
        justifyContent: "flex-end",
        backdropFilter: "blur(12px)",
        backgroundColor: "rgba(255 255 255 / 0.2)",
        [theme.getColorSchemeSelector("dark")]: {
          backgroundColor: "rgba(19 19 24 / 0.4)",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          width:
            "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 700, 100%)",
          maxWidth: "100%",
          px: 2,
        }}
      >
        <Box
          component="header"
          sx={{
            py: 3,
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              gap: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <IconButton variant="soft" color="primary" size="sm">
              <BadgeRoundedIcon />
            </IconButton> */}
            <img src={branding.BRAND_LOGO} alt="dxb logo" loading="lazy" />
            <Typography component="h1" fontSize={24} fontWeight="xl">
              {branding.BRAND_NAME}
            </Typography>
          </Box>
        </Box>
        <Box
          component="main"
          sx={{
            my: "auto",
            py: 2,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "70%",
            maxWidth: "100%",
            mx: "auto",
            borderRadius: "sm",
            "& form": {
              display: "flex",
              flexDirection: "column",
              gap: 2,
            },
            [`& .${formLabelClasses.asterisk}`]: {
              visibility: "hidden",
            },
          }}
        >
          {/* <AuthLoginComponent /> */}
          {!isAuthComponent ? (
            <>
              <IntroComponent handleOnClickSignIn={handleOnClickSignIn} />
              <Stack sx={{ display: "flex", flexDirection: "row" }} gap={1}>
                <Typography level="body-md" 
                // sx={{color: '#7D5260'}}
                >Already a member?</Typography>
                <Typography
                  level="body-md"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color="primary"
                  onClick={handleOnClickSignIn}
                >
                  Sign In
                </Typography>
              </Stack>
            </>
          ) : (
            <AuthLoginComponent
              onLogin={onLogin}
              handleOnClickSignIn={handleOnClickSignIn}
            />
          )}
        </Box>
        {/* <Box component="footer" sx={{ py: 3 }}>
          <Typography level="body-xs" textAlign="center">
            © Your company {new Date().getFullYear()}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default LoginLeftComponent;
