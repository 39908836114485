import React from "react";
import {
  Box,
  Button,
  Dropdown,
  IconButton,
  MenuButton,
  MenuItem,
  Sheet,
  Stack,
  Menu,
  Switch,
  Badge,
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";
import useNavbarController from "./navbar-controller";
import {
  Add,
  Delete,
  Language,
  Logout,
  MenuOutlined,
  MoreVert,
  Star,
  StarOutline,
  Textsms,
  Tune,
} from "@mui/icons-material";
import ColorSchemeToggle from "../color-scheme-toggle";
import DrawerComponent from "../../pages/dashboard/components/drawer-component";
import brand from "../../resources/branding";

const avatarPlaceholder =
  "https://camo.githubusercontent.com/c6fe2c13c27fe87ac6581b9fe289d2f071bd1b4ef6f3e3c5fc2aba0bbc23fd88/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f372f37632f50726f66696c655f6176617461725f706c616365686f6c6465725f6c617267652e706e67";

const Navbar = () => {
  const {
    userDetails,
    selectedLanguage,
    toggleMobileDrawer,
    isDrawerOpen,
    isLoggedIn,
    allThreads,
    newThreadLoadingStatus,
    widgetConfigsData,
    handleAddingNewThread,
    handleToLogoutUser,
    selectedThreadData,
    handleOnStarredThread,
    handleOnchangeLanguage,
    handleOnDeleteThread,
    LANGUAGES_CONSTANT,
    isMapVisible,
    toggleMap,
    toggleWebDrawer
  }: any = useNavbarController();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        top: 0,
        p: 2,
        zIndex: 1000,
        backgroundColor: "background.body",
        borderBottom: "1px solid",
        borderColor: "divider",
        position: "sticky",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton onClick={toggleMobileDrawer}>
            <MenuOutlined />
          </IconButton>
        </Box>
        <Stack
          sx={{
            width: { xs: 25, md: 35 },
            display: { xs: "none", md: "block" },
          }}
        >
          <img src={brand.BRAND_LOGO} alt="dxb logo" loading="lazy" />
        </Stack>
        <Typography
          component="h1"
          sx={{
            fontSize: { xs: 17, md: 24 },
            display: { xs: "none", md: "block" },
          }}
          fontWeight="xl"
        >
          {brand.BRAND_NAME}
        </Typography>
        <Typography
          component="h1"
          sx={{
            fontSize: { xs: 17, md: 24 },
            display: { xs: "block", md: "none" },
          }}
          fontWeight="xl"
        >
          {selectedThreadData?.title || brand.BRAND_NAME}
        </Typography>
        <Stack
          gap={3}
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            alignItems: "center",
            ml: 4,
          }}
        >
          <Button
            startDecorator={<Add sx={{ width: 17 }} />}
            variant="soft"
            color="primary"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // backgroundColor: "#E8DEF8",
              py: 1,
              px: 2,
              borderRadius: 20,
              cursor: "pointer",
            }}
            onClick={handleAddingNewThread}
            loading={newThreadLoadingStatus}
          >
            Add Thread
          </Button>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            gap={1}
            onClick={toggleWebDrawer}
          >
            <Textsms sx={{ width: 17 }} />
            <Typography>Threads</Typography>
          </Stack>
          {/* <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            gap={1}
            onClick={toggleDrawer}
          >
            <Podcasts sx={{ width: 17 }} />
            <Typography>News</Typography>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            gap={1}
            onClick={toggleDrawer}
          >
            <TrendingUp sx={{ width: 17 }} />
            <Typography>Top Areas</Typography>
          </Stack> */}
        </Stack>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "row",
          gap: 2,
        }}
      >
        <Dropdown sx={{ p: 1 }}>
          <MenuButton sx={{ border: 0, p: 1 }} startDecorator={<Language />}>
            {selectedLanguage}
          </MenuButton>
          <Menu>
            {LANGUAGES_CONSTANT?.map((lang: any) => {
              return (
                <MenuItem
                  selected={selectedLanguage === lang?.text}
                  key={lang.id}
                  onClick={() => handleOnchangeLanguage(lang?.id)}
                >
                  {lang?.text}
                </MenuItem>
              );
            })}
          </Menu>
        </Dropdown>
        <ColorSchemeToggle backGroundColor={!isLoggedIn ? "#FFD8E4" : ""} />
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{
              root: { variant: "plain", color: "neutral" },
            }}
          >
            <Box
              sx={{
                gap: 1,
                alignItems: "center",
                display: { xs: "none", sm: "flex" },
                cursor: "pointer",
              }}
            >
              {userDetails?.profilePic && (
                <Avatar
                  variant="outlined"
                  size="sm"
                  src={userDetails?.profilePic || avatarPlaceholder}
                />
              )}
              <Box sx={{ flex: 1 }}>
                {userDetails?.userName && (
                  <Typography level="title-sm" textAlign="left">
                    {userDetails?.userName || ""}
                  </Typography>
                )}
                {userDetails?.email && (
                  <Typography level="body-xs">
                    {userDetails?.email || ""}
                  </Typography>
                )}
              </Box>
            </Box>
          </MenuButton>
          <Menu>
            <MenuItem onClick={handleToLogoutUser}>
              <Logout /> Logout
            </MenuItem>
          </Menu>
        </Dropdown>
        {/* <ColorSchemeToggle sx={{ alignSelf: 'center' }} /> */}
      </Box>
      {allThreads.length !== 0 && (
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "row",
            gap: 2,
          }}
        >
          <IconButton
            onClick={() => handleOnStarredThread(selectedThreadData.id)}
          >
            {selectedThreadData?.is_starred ? <Star /> : <StarOutline />}
          </IconButton>
          
          <Dropdown popperPlacement="bottom-end" sx={{ p: 1, zIndex: 1200 }}>
          {Object.entries(widgetConfigsData)?.length > 0 && <Badge color="primary" size="md" badgeInset="8px 8px 0 0">
                    <IconButton onClick={toggleMobileDrawer}>
                      <Tune />
                    </IconButton>
                  </Badge>}
            <MenuButton
              slots={{ root: IconButton }}
              slotProps={{
                root: { variant: "plain", color: "neutral" },
              }}
            >
              <MoreVert />
            </MenuButton>
            <Menu>
              <MenuItem
                onClick={() => handleOnDeleteThread(selectedThreadData.id)}
              >
                <Delete />
                Delete Thread
              </MenuItem>
              <MenuItem onClick={() => toggleMap(!isMapVisible)}>
                          <Switch
                            checked={isMapVisible}
                            onChange={(event) =>
                              toggleMap(event.target.checked)
                            }
                          />
                          Map
                        </MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      )}
      <DrawerComponent
        toggleDrawer={toggleMobileDrawer}
        isDrawerOpen={isDrawerOpen}
        handleToLogoutUser={handleToLogoutUser}
      />
    </Box>
  );
};

export default Navbar;
