import {useEffect} from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AppLayout from "./app-layout";
import AppError from "./error-layout";
import Dashboard from "../pages/dashboard";
import Login from "../pages/auth/login";
import { callToGetRestrictedUserApi, getLoggedInStatus, getUserData, updateAuthToken, updateLoggedInStatus, updateUserLogginDetails, usedToLogoutUser } from "../store/auth";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { store } from '../store/configureStore';
import LoadingComponent from '../components/loading-component';
import { signOut } from "firebase/auth";
import { callThreadsApi } from '../store/entities/chat';

onAuthStateChanged(auth, (user:any) => {
  const dispatch = store.dispatch;
  if (user) {
    dispatch(updateUserLogginDetails({profilePic: user?.photoURL, userName: user?.displayName, email: user?.email}))
    user.getIdToken().then((token: any) => {
      // dispatch({ type: setUserEmail.type, payload: user.email })
      
      dispatch({type: updateAuthToken.type, payload: token})
      dispatch(updateLoggedInStatus(true));
    });
  } else {
    console.log('else block....');
    signOut(auth)
    .then(() => {
      dispatch(usedToLogoutUser());
    })
    .catch((error) => {
      console.log(error);
    });
    dispatch(usedToLogoutUser());
    console.log('sign out');
  }
});

const AppRouter = () => {
  const isLoggedIn = useSelector(getLoggedInStatus);

  const dispatch = useDispatch<Dispatch>()

  // useEffect(() => {
  //  if(userData?.accessToken){
  //   dispatch(updateLoggedInStatus(true));
  //  }
  // }, [dispatch, userData])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(callThreadsApi());
      dispatch(callToGetRestrictedUserApi());
    }
  }, [isLoggedIn]);
  

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout isLoggedIn={isLoggedIn} />,
      errorElement: <AppError />,
      children: [
        {
          path: "/",
          element: isLoggedIn === null ? <LoadingComponent /> : isLoggedIn ? <Dashboard /> : <Login />,
        },
      ],
    },
  ]);
  return <RouterProvider router={appRouter} />;
};

export default AppRouter;
