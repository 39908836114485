import { useDispatch, useSelector } from "react-redux";

import {
  callToGetRestrictedUserApi,
  getLoggedInStatus,
  getSelectedLanguage,
  getUserData,
  updateSelectedLanguage,
} from "../../store/auth";
import { useCallback, useContext, useEffect, useState } from "react";
import { DrawerContext } from "../context/drawer-context";
import {
  callToAddNewThread,
  callToDeleteThread,
  callToStarredThread,
  getActiveThreadChat,
  getAllThreads,
  getNewThreadLoadingStatus,
  getWidgetConfigs,
} from "../../store/entities/chat";
import FirebaseAuthProvider from "../../utils/firebase-auth";
import { LANGUAGES_CONSTANT } from "../../resources/constant";

const useNavbarController = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const userDetails = useSelector(getUserData);
  const isLoggedIn = useSelector(getLoggedInStatus);
  const newThreadLoadingStatus = useSelector(getNewThreadLoadingStatus);
  const selectedThreadData = useSelector(getActiveThreadChat)?.activeThread;
  const allThreads = useSelector(getAllThreads);
  const userSelectedLanguage = useSelector(getSelectedLanguage);
  const widgetConfigsData = useSelector(getWidgetConfigs);

  const dispatch = useDispatch<Dispatch>();

  const { toggleMobileDrawer, isDrawerOpen, isMapVisible, toggleMap, toggleWebDrawer } = useContext(
    DrawerContext
  ) as any;

  const firebase = new FirebaseAuthProvider();

  useEffect(() => {
    const selectedLanguage = LANGUAGES_CONSTANT?.filter(
      (lang: any) => lang?.id === userSelectedLanguage
    );
    setSelectedLanguage(selectedLanguage[0]?.text);
  }, [userSelectedLanguage]);

  //TODO: need to do, now api is failing: error : {id_slug is required , title is required}
  const handleAddingNewThread = () => {
    dispatch(callToAddNewThread());
  };

  const handleToLogoutUser = () => {
    firebase.signOutTheUser();
  };

  const handleOnchangeLanguage = useCallback(
    (language: string): void => {
      dispatch(updateSelectedLanguage(language));
    },
    [dispatch]
  );

  const handleOnStarredThread = (threadId: number) => {
    if (selectedThreadData?.id) {
      dispatch(callToStarredThread(threadId, !selectedThreadData?.is_starred));
      // setIsThreadStarred(!selectedThreadData.activeThread?.is_starred);
    }
  };

  const handleOnDeleteThread = (threadId: number) => {
    dispatch(callToDeleteThread(threadId));
  };

  return {
    userDetails,
    selectedLanguage,
    isLoggedIn,
    toggleMobileDrawer,
    isDrawerOpen,
    newThreadLoadingStatus,
    selectedThreadData,
    allThreads,
    LANGUAGES_CONSTANT,
    isMapVisible,
    toggleMap,
    toggleWebDrawer,
    widgetConfigsData,
    handleOnDeleteThread,
    handleOnStarredThread,
    handleToLogoutUser,
    handleAddingNewThread,
    handleOnchangeLanguage,
  };
};

export default useNavbarController;
