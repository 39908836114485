import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
} from "@mui/joy";
import React from "react";
import OneLineTextEllipseWrapper from "../common/one-line-test-ellipse-wrapper";

const AccordionSwitchComponent: React.FC<any> = (props) => {
  return (
    <Accordion sx={{ ph: 1, pt: 0.5, pb: 0.5 }}>
      <AccordionSummary
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 0.7,
        }}
      >
        <Typography level="title-sm">{props?.label_name}</Typography>
        <OneLineTextEllipseWrapper>
          <Typography level="body-sm">
            {props?.default_val?.toString()}
          </Typography>
        </OneLineTextEllipseWrapper>
      </AccordionSummary>
      <AccordionDetails>
        <Typography level="body-xs" sx={{ mb: 0.5 }}>
          {props?.help_text}
        </Typography>
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography level="body-sm">{props?.label_name}</Typography>
          <Switch
            checked={props?.currentData}
            onChange={(event) => props.handleSwitchCase(event.target.checked)}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSwitchComponent;
