import React, { createContext, useCallback, useEffect, useState } from "react";
import useMediaQuery from "../../utils/useMediaQuery";
import {
  getUserPreferencesStorageData,
  storeUserPreferencesData,
} from "../../services/localstorage-service";

export const DrawerContext = createContext<any>({
  toggleDrawer: () => {},
  isDrawerOpen: false,
});

const DrawerContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isWebDrawerOpen, setIsWebDrawerOpen] = useState<boolean>(true);
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);

  const userPreferencesData = getUserPreferencesStorageData();

  const isMobile = useMediaQuery(700);

  useEffect(() => {
    if (!isMobile) {
      userPreferencesData
        ? setIsMapVisible(userPreferencesData?.mapInWeb)
        : setIsMapVisible(true);
    } else {
      // setIsMapVisible(false)
      // tIsDrawerOpen(true);
      userPreferencesData
        ? setIsMapVisible(userPreferencesData?.mapInMobile)
        : setIsMapVisible(false);
    }
  }, [isMobile, userPreferencesData]);

  const toggleMobileDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  const toggleWebDrawer = useCallback(() => {
    setIsWebDrawerOpen(!isWebDrawerOpen);
  }, [isWebDrawerOpen]);

  const toggleMap = useCallback(
    (value: boolean) => {
      if (isMobile) {
        storeUserPreferencesData("mapInMobile", value);
      } else {
        storeUserPreferencesData("mapInWeb", value);
      }
      setIsMapVisible(value);
    },
    [setIsMapVisible, isMobile]
  );

  return (
    <DrawerContext.Provider
      value={{
        toggleMobileDrawer,
        toggleWebDrawer,
        toggleMap,
        isDrawerOpen,
        isWebDrawerOpen,
        isMapVisible,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContextProvider;
