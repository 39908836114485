import { Sheet } from '@mui/joy'
import React from 'react'
import CircularProgress from '@mui/joy/CircularProgress';

const LoadingComponent = () => {
  return (
    <Sheet sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100dvh'}}>
         <CircularProgress color="primary" variant="solid" />
    </Sheet>
  )
}

export default LoadingComponent