import React, { Fragment, useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemDecorator,
  Stack,
  Typography,
} from "@mui/joy";
import moment from "moment";
import { Delete, Done, Edit, HighlightOff, Star } from "@mui/icons-material";
import Input from "@mui/joy/Input";

interface iDrawerComponentViewProps {
  // isDrawerOpen: boolean;
  selectedThreadEditText: any;
  selectedEditThreadId: number;
  // toggleDrawer(): void;
  heightOFAComponent: any;
  allThreads: any;
  thread: any;
  activeThread: number;
  newThreadLoadingStatus: boolean;
  handleOnThreadsClick(_: number): void;
  handleOnDeleteThread(e: any, _: number): void;
  handleToSelectEditThreadTitleId(e: any, _: number, t: string): void;
  handleThreadTitleEditMethod(_: any): void;
  handleOnClickSubmitEditedTitle(_: any, d: any): void;
  handleOnCancelEditing(_: any): void;
}

const UnderlineInput = ({
  editText = "",
  threadId,
  handleOnCancelEditing,
  handleOnClickSubmitEditedTitle,
  handleSelectedEditText = (_: any) => {},
}: any) => {
  return (
    <Input
      placeholder="Add title"
      value={editText}
      name={threadId}
      autoFocus
      onChange={handleSelectedEditText}
      startDecorator={
        <IconButton onClick={handleOnCancelEditing}>
          <HighlightOff />
        </IconButton>
      }
      sx={{
        "&::before": {
          borderBottom: "1.5px solid var(--Input-focusedHighlight)",
          transform: "scaleX(0)",
          left: "2.5px",
          right: "2.5px",
          bottom: 0,
          top: "unset",
          transition: "transform .15s cubic-bezier(0.1,0.9,0.2,1)",
          borderRadius: 0,
          borderBottomLeftRadius: "64px 20px",
          borderBottomRightRadius: "64px 20px",
        },
        "&:focus-within::before": {
          transform: "scaleX(1)",
        },
        borderRadius: 0,
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderBottom: "1.5px solid var(--Input-focusedHighlight)",
      }}
      onKeyDown={(event) => {
        console.log("event key =>>", event.key);
        if (event.key === "Escape") {
          handleOnCancelEditing();
        } else {
          if (event.key === "Enter") {
            handleOnClickSubmitEditedTitle();
          }
        }
      }}
    />
  );
};
const SideBarThreadComponent: React.FC<iDrawerComponentViewProps> = ({
  allThreads = [],
  thread,
  activeThread = 0,
  selectedThreadEditText,
  handleOnThreadsClick = (_: number) => {},
  // toggleDrawer = () => {},
  handleOnDeleteThread = (e: any, _: number) => {},
  handleToSelectEditThreadTitleId = (e: any, _: number) => {},
  handleThreadTitleEditMethod = (_: any) => {},
  handleOnCancelEditing,
  handleOnClickSubmitEditedTitle,
}) => {
  const [showIcons, setShowIcons] = useState<string | null>(null);
  return (
    <Box>
      {/* ---- starred list ---- */}

      <React.Fragment key={thread?.id}>
        {selectedThreadEditText?.threadId &&
        selectedThreadEditText?.threadId === thread?.id ? (
          <ListItem
            variant="soft"
            color={activeThread === thread?.id_slug ? "primary" : "neutral"}
            sx={{
              position: "relative",
              flexDirection: "column",
              alignItems: "initial",
              gap: 1,
              p: 1,
            }}
          >
            <Box
              sx={{
                lineHeight: 1.5,
                textAlign: "right",
              }}
            >
              <Typography
                level="body-xs"
                display={{ xs: "none", md: "block" }}
                noWrap
              >
                {moment(
                  thread?.datetime_created,
                  "YYYYMMDD hh:mm:ss"
                ).fromNow()}
              </Typography>
            </Box>
            <UnderlineInput
              editText={selectedThreadEditText?.title}
              threadId={selectedThreadEditText?.id}
              handleSelectedEditText={handleThreadTitleEditMethod}
              handleOnCancelEditing={handleOnCancelEditing}
              handleOnClickSubmitEditedTitle={(e: any) =>
                handleOnClickSubmitEditedTitle(e, thread?._id_slug)
              }
            />
          </ListItem>
        ) : (
          <ListItem
            color={activeThread === thread?.id_slug ? "primary" : "neutral"}
            variant="soft"
            sx={{
              position: "relative",
              p: 1,
            }}
            onClick={
              activeThread === thread?.id_slug
                ? () => {}
                : () => {
                    handleOnThreadsClick(thread?.id_slug || null);
                    // toggleDrawer();
                  }
            }
            onMouseOver={() => setShowIcons(thread?.id_slug)}
            onMouseLeave={() => setShowIcons(null)}
          >
            <ListItem
              sx={{
                flexDirection: "column",
                alignItems: "initial",
                gap: 1,
                width: "100%",
                cursor: "pointer",
              }}
            >
              <Stack
                direction="row"
                gap={2}
                sx={{ alignItems: "center", width: "100%" }}
              >
                {thread?.is_starred && <Star />}
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        level="title-sm"
                        sx={{
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {thread?.title || ""}
                      </Typography>
                    </Box>
                    {/* { && ("visible" : null  */}
                   
                      <ListItemDecorator
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "right",
                          visibility: showIcons === thread?.id_slug ? "visible" : 'hidden' ,
                          gap: 2,
                        }}
                      >
                        <Stack
                          sx={{ width: 15 }}
                          onClick={(e: any) =>
                            handleOnDeleteThread(e, thread.id)
                          }
                        >
                          <Delete sx={{ width: 15 }} />
                        </Stack>
                        <Stack
                          sx={{ width: 15 }}
                          onClick={(e: any) =>
                            handleToSelectEditThreadTitleId(
                              e,
                              thread.id,
                              thread?.title
                            )
                          }
                        >
                          <Edit sx={{ width: 15 }} />
                        </Stack>
                      </ListItemDecorator>
                    {/* )} */}
                  </Stack>
                  <Box
                    sx={{
                      lineHeight: 1.5,
                    }}
                  >
                    <Typography
                      level="body-xs"
                      display={{ xs: "none", md: "block" }}
                      noWrap
                    >
                      {moment(
                        thread?.datetime_created,
                        "YYYYMMDD hh:mm:ss"
                      ).fromNow()}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </ListItem>
          </ListItem>
        )}
      </React.Fragment>
    </Box>
  );
};

export default SideBarThreadComponent;
