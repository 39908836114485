import useWidgetComponentController from "./widget-component-controller";
import WidgetComponentView from "./widget-component-view";

const WidgetComponent: React.FC<any> = () => {
  const {
    widgetConfigurationData,
    isLoading,
    isMessagePending,
    handleWidgetController,
    handleOnSubmit,
    handleOnReset,
  } = useWidgetComponentController();
  return (
    <WidgetComponentView
      widgetConfigurationData={widgetConfigurationData}
      isLoading={isLoading}
      isMessagePending={isMessagePending}
      handleWidgetController={handleWidgetController}
      handleOnSubmit={handleOnSubmit}
      handleOnReset={handleOnReset}
    />
  );
};

export default WidgetComponent;
