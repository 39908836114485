import { useCallback, useContext, useEffect, useState } from "react";
import widgetData from "../../resources/output.json";
import { useDispatch, useSelector } from "react-redux";
import {
  callToPostUserChat,
  getActiveThreadChat,
  getUserPendingChatStateAndData,
  getWidgetConfigs,
} from "../../store/entities/chat";
import { DrawerContext } from "../context/drawer-context";
import useMediaQuery from "../../utils/useMediaQuery";

const jsonData: any = widgetData;

let options: any = {
  text: "",
  role: "user",
  direct_values: {}
};

const useWidgetComponentController = () => {
  const [widgetConfigurationData, setWidgetConfigurationData] = useState<any>(
    {}
  );

  const currentThreadChats = useSelector(getActiveThreadChat);
  const widgetConfigsData = useSelector(getWidgetConfigs);
  const pendingChat = useSelector(getUserPendingChatStateAndData);
  const isMessagePending = useSelector(getUserPendingChatStateAndData)?.isLoading || false;

  const isMobile = useMediaQuery(700);

  const {toggleMobileDrawer} = useContext(DrawerContext) as any;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (widgetConfigsData && Object.entries(widgetConfigsData)?.length > 0) {
      let widgetOption: any = {};
      Object.entries(widgetConfigsData)?.forEach(([key, value]: any) => {
        if (value?.widget_type === "NumSliderWidget") {
          widgetOption[key] = {
            ...value,
            prevData: value?.default_val,
            currentData: 0,
          };
        }
        if (value?.widget_type === "DropdownMultiSelectWidget") {
          widgetOption[key] = {
            ...value,
            prevData: value?.default_val,
            currentData: value?.default_val,
          };
        }
        if (value?.widget_type === "DropdownWidget") {
          widgetOption[key] = {
            ...value,
            prevData: value?.default_val,
            currentData: "",
          };
        }
        if (value?.widget_type === "ToggleWidget") {
          widgetOption[key] = {
            ...value,
            prevData: value?.default_val,
            currentData: value?.default_val,
          };
        }
      });
      setWidgetConfigurationData(widgetOption);
    }else {
      setWidgetConfigurationData(widgetConfigsData)
    }
  }, [widgetConfigsData, setWidgetConfigurationData]);

  const handleOnSubmit = useCallback(() => {

    const textOptions: any = [];
    
    const directValues = Object.create(options.direct_values);

    Object.entries(widgetConfigurationData)?.forEach(([key, value]: any) => {
      if (
        widgetConfigurationData?.[key]?.widget_type ===
          "DropdownMultiSelectWidget" &&
        value?.currentData?.toString() !== value?.prevData?.toString()
      ) {
        const newText: any = [];
        value?.currentData?.forEach((item: any) => {
          newText.push(widgetConfigurationData[key].options[item]);
        });

        const optionText = ` ${
          value?.label_name
        }: **${newText?.toString()}**`;
        textOptions.push(optionText);
        directValues[key] = value?.currentData;
      }
      if (
        widgetConfigurationData?.[key]?.widget_type === "NumSliderWidget" &&
        value?.currentData &&
        value?.currentData !== value?.prevData
      ) {
        const optionText = `${value?.label_name}: **${Array.isArray(value?.currentData) ? value.currentData[0] + " - " + value.currentData[1] : value?.currentData}**`;
        textOptions.push(optionText);
        directValues[key] = value?.currentData;
        
      }
      if (
        widgetConfigurationData?.[key]?.widget_type === "DropdownWidget" &&
        value?.currentData &&
        value?.currentData !== value?.prevData
      ) {
        const optionText = `${value?.label_name}: **${
          value?.options[value?.currentData]
        }**`;
        textOptions.push(optionText);
        directValues[key] = value?.currentData;
      }
      if (
        widgetConfigurationData?.[key]?.widget_type === "ToggleWidget" &&
        value?.currentData?.toString() !== value?.prevData?.toString()
      ) {
        const optionText = `${
          value?.label_name
        }: **${value?.currentData?.toString()}**`;
        textOptions.push(optionText);
        directValues[key] = value?.currentData;
      };

       options = {
        ...options,
        direct_values: directValues
       }
      if (options.direct_values?.hasOwnProperty(key)) {
        let newText = "";

        [...textOptions]?.forEach((item: string) => {
          newText = `${newText} ${item}  \n`;
        });
        options.text = String.raw`${newText.trim()}`;
        
        
      }
    });
    if(Object.entries(options?.direct_values)?.length > 0){
    dispatch(
      callToPostUserChat(options, currentThreadChats?.activeThread?.id_slug)
    );
    if(isMobile){
      toggleMobileDrawer();
    }
    }
  }, [
    currentThreadChats?.activeThread?.id_slug,
    widgetConfigurationData,
    isMobile
  ]);

  const handleOnReset = useCallback(() => {
    let widgetOption: any = {};
    Object.entries(widgetConfigsData)?.forEach(([key, value]: any) => {
      if (value?.widget_type === "NumSliderWidget") {
        widgetOption[key] = {
          ...value,
          prevData: value?.default_val,
          currentData: 0,
        };
      }
      if (value?.widget_type === "DropdownMultiSelectWidget") {
        widgetOption[key] = {
          ...value,
          prevData: value?.default_val,
          currentData: value?.default_val,
        };
      }
      if (value?.widget_type === "DropdownWidget") {
        widgetOption[key] = {
          ...value,
          prevData: value?.default_val,
          currentData: "",
        };
      }
      if (value?.widget_type === "ToggleWidget") {
        widgetOption[key] = {
          ...value,
          prevData: value?.default_val,
          currentData: value?.default_val,
        };
      }
    });
    setWidgetConfigurationData(widgetOption);
  }, [widgetConfigsData]);

  const handleWidgetController = (key: string, value: any) => {
    setWidgetConfigurationData((prev: any) => {
      let choosenData: any;

      if (
        prev[key]?.widget_type === "DropdownMultiSelectWidget" &&
        Array.isArray(prev[key]?.currentData)
      ) {
        if (prev[key]?.currentData?.includes(value)) {
          const filterData = prev[key]?.currentData?.filter(
            (item: any) => item !== value
          );
          // console.log('filterData =>', filterData)
          // if(filterData?.length > 0){
          choosenData = filterData;
          // }
        } else {
          choosenData = [...prev[key]?.currentData, value];
        }
      } else if (prev[key]?.widget_type === "NumSliderWidget") {
        choosenData = value;
      } else if (prev[key]?.widget_type === "DropdownWidget") {
        choosenData = value;
      } else if (prev[key]?.widget_type === "ToggleWidget") {
        choosenData = value;
      } else {
        choosenData = null;
      }
      return {
        ...prev,
        [key]: {
          ...prev[key],
          currentData: choosenData,
        },
      };
    });
  };

  const isLoading = pendingChat?.isLoading || false;

  return {
    widgetConfigurationData,
    isLoading,
    isMessagePending,
    handleWidgetController,
    handleOnSubmit,
    handleOnReset,
  };
};

export default useWidgetComponentController;
