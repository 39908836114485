export const getLocalStorageService = (key: string) => {
  let user: any = {
    email: null,
    id: null,
    userName: null,
    phoneNumber: null,
    profilePic: null,
    uid: null,
    isLoggedIn: false,
    accessToken: null,
  };

  if (
    typeof window !== "undefined" &&
    localStorage &&
    localStorage.getItem("@user")
  )
    user = JSON.parse(localStorage.getItem("@user") as any);

  return user[key] || null;
};

export const getUserPreferencesStorageData = (key?: string) => {
  let preferences: any = {
    theme: "light",
    language: "en",
    mapInMobile: false,
    mapInWeb: true,
  };

  if (
    typeof window !== "undefined" &&
    localStorage &&
    localStorage.getItem("@userPreferences")
  )
    preferences = JSON.parse(localStorage.getItem("@userPreferences") as any);

  return key ? preferences[key] : preferences;
};

export const storeUserPreferencesData = (
 key?: any,value?: any
) => {
  let preferencesData: any = {
    theme: "light",
    language: "en",
    mapInMobile: false,
    mapInWeb: true,
  };
  let preferences: any = localStorage.getItem("@userPreferences");

  if (preferences) {
    preferences = JSON.parse(localStorage.getItem("@userPreferences") as any);
    localStorage.removeItem("@userPreferences");

    preferences[key] = value;

    localStorage.setItem("@userPreferences", JSON.stringify(preferences));
  } else {
    preferencesData[key] = value;
    localStorage.setItem("@userPreferences", JSON.stringify(preferencesData));
  }
};
