import { useSelector, useDispatch } from "react-redux";
import { getSelectedLanguage, getUserData, updateSelectedLanguage } from "../../../../store/auth";
import {
  callToAddNewThread,
  getNewThreadLoadingStatus,
} from "../../../../store/entities/chat";
import { LANGUAGES_CONSTANT } from "../../../../resources/constant";
import { useCallback, useMemo } from "react";

const useDrawerController = () => {
  const userDetails = useSelector(getUserData);
  const newThreadLoadingStatus = useSelector(getNewThreadLoadingStatus);
  const userSelectedLanguage = useSelector(getSelectedLanguage);

  const dispatch = useDispatch<Dispatch>();

  const handleAddingNewThread = () => {
    dispatch(callToAddNewThread());
  };

  const handleOnchangeLanguage = useCallback((language: string): void => {
    dispatch(updateSelectedLanguage(language));
    // const selectedLanguage = LANGUAGES_CONSTANT?.filter((lang: any) => lang?.id === language);
    // setSelectedLanguage(selectedLanguage[0]?.text);
  }, [dispatch]);

  const selectedLanguage = useMemo(
    () =>
      LANGUAGES_CONSTANT?.filter(
        (lang) => lang?.id === userSelectedLanguage
      )?.[0]?.text,
    [userSelectedLanguage]
  );

  return {
    userDetails,
    selectedLanguage,
    newThreadLoadingStatus,
    handleOnchangeLanguage,
    handleAddingNewThread,
  };
};

export default useDrawerController;
