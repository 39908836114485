import axios from "axios";
import { store } from "../store/configureStore";
import { usedToLogoutUser } from "../store/auth";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
/**
 * add axios interceptor if needed..
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
   
    if (error.response.status === 401 || error.response.status === 502) {
        localStorage.removeItem("@user");
        
      store.dispatch(usedToLogoutUser());
      signOut(auth)
      .then(() => {
        store.dispatch(usedToLogoutUser());
      })
      .catch((error: any) => {
        console.log(error);
      });
      // handleRefreshToken();
      // localStorage.removeItem("@user");
     
      return;
    }
    return Promise.reject(error);
  }
);
const httpServices = axios;

export default httpServices;
