import React, { useEffect } from "react";

import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import { useColorScheme } from "@mui/joy/styles";
import { DarkModeOutlined } from "@mui/icons-material";
import { Typography } from "@mui/joy";
import { storeUserPreferencesData, getUserPreferencesStorageData } from "../../services/localstorage-service";
import { useDispatch } from "react-redux";
import { updateWebsiteThemeMode } from "../../store/auth";

interface colorSchemeParams extends IconButtonProps {
  backGroundColor?: string;
  needToShowThemeText?: boolean;
}

function ColorSchemeToggle({ onClick, backGroundColor, ...props }: colorSchemeParams) {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(getUserPreferencesStorageData("theme"));

  const dispatch = useDispatch<Dispatch>();
  
  React.useEffect(() => {
    setMounted(true);
  }, []);

  const handleOnClick = (event: any) => {
    if (mode === "light") {
      setMode("dark");
      dispatch(updateWebsiteThemeMode("dark"));
      storeUserPreferencesData("theme", "dark")
    } else {
      storeUserPreferencesData("theme", "light")
      dispatch(updateWebsiteThemeMode("light"));
      setMode("light");
    }
    onClick?.(event);
  };

  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="md"
      variant="outlined"
      // color="neutral"
      aria-label="toggle light/dark mode"
      {...props}
      onClick={handleOnClick}
      sx={{
        borderRadius: 100,
        p: 1,
        backgroundColor: backGroundColor || "", // '#FFD8E4',
        outline: "none",
        border: "none",
        gap: 1
      }}
    >
      {mode === "light" ? <DarkModeOutlined /> : <LightModeRoundedIcon />}
      {props?.needToShowThemeText && mode && <Typography>{`${mode === "light" ? 'Dark': 'Light'} Theme`}</Typography>}
    </IconButton>
  );
}

export default ColorSchemeToggle;
