import { Box, Tooltip } from "@mui/joy";
import React from "react";

const OneLineTextEllipseWrapper: React.FC<any> = ({ children, title = "" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 1,
        textOverflow: "ellipsis",
      }}
    >
      <Box sx={{
        
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default OneLineTextEllipseWrapper;
